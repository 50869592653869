import * as React from 'react';
import * as styles from './banner-kit-corredor.module.css';
import * as basestyles from './../layout.module.css';
import { StaticImage } from 'gatsby-plugin-image';

const BannerKitCorredor = () => {
    return (
        <section className={[basestyles.banner, styles.bannerKitCorredor].join(' ')}>
            <div className={[basestyles.bannerContainer, styles.runnerKitContainer, basestyles.loadAnimation].join(' ')}>
                <div className={[styles.runnerKitImageWrapper, styles.grinchImageWrapper].join(' ')}>
                    <StaticImage 
                        alt="Grinch"
                        src="./../../images/banners/kit-corredor/grinch-RSR.svg"
                    />
                </div>
                <div className={[styles.runnerKitImageWrapper, styles.jengiImageWrapper].join(' ')}>
                    <StaticImage 
                        alt="Jengi"
                        src="./../../images/banners/kit-corredor/jengi-RSR.svg"
                    />
                </div>
                <div className={styles.runnerKitTextContainer}>
                    <div className={styles.runnerKitList}>
                        <div className={[styles.runnerKitItem, styles.runnerKitSanta].join(' ')}>
                            <h3 className={[styles.runnerKitTitle, styles.runnerKitTitleSanta].join(' ')}>Santa Claus Kit</h3>
                            <div className={styles.runnerKitItemContent}>
                                <p><span>- </span> Incluye traje de Santa (Pantalón, saco, gorro, y barba)</p>
                                <p><span>- </span> Medalla conmemorativa edición 2022</p>
                            </div>
                        </div>
                        <div className={styles.runnerKitHorizontalDivider}></div>
                        <div className={[styles.runnerKitItem, styles.runnerKitElf].join(' ')}>
                            <h3 className={[styles.runnerKitTitle, styles.runnerKitTitleElf].join(' ')}>Elfo kit</h3>
                            <h5 className={[styles.runnerKitTitle, styles.runnerKitSubtitleElf].join(' ')}>Menores de 12 años</h5>
                            <div className={styles.runnerKitItemContent}>
                                <p><span>- </span> Incluye traje de Elfo (Pantalón, saco, y gorro con orejas)</p>
                                <p><span>- </span> Medalla conmemorativa edición 2022</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.runnerKitRecommendation}>
                        <p><span>RECOMENDACIÓN:</span> El día de la carrera llevar una playera blanca, tanto niños como adultos.</p>
                        <p>Los reconocimientos se entregarán a los tres primeros lugares de cada categoría.</p>
                    </div>
                    <div className={styles.runnerKitDonation}>
                        <p>Su kit incluye <span>aportación para la compra de juguetes</span> para niños de escasos recursos.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BannerKitCorredor;