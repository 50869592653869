import * as React from "react";
import { useState } from 'react';
import Layout from "../components/layout";
import BannerPrincipal from "../components/banners/banner-principal";
import BannerInscripciones from "../components/banners/banner-inscripciones";
import BannerViveRSR from "../components/banners/banner-viversr";
import BannerSonrisa from "../components/banners/banner-sonrisa";
import BannerGaleria from "../components/banners/banner-galeria";
import BannerPatrocinadores from "../components/banners/banner-patrocinadores";
import BannerEvento from "../components/banners/banner-evento";
import BannerKitCorredor from "../components/banners/banner-kit-corredor";
import BannerSwitcher from "../components/utils/bannerswitcher";
import BannerRibbon from "../components/utils/banner-ribbon";
import Seo from "../components/utils/seo";

const subBanners = {
    'evento': [<BannerEvento />, <BannerSonrisa />],
    'inscripcion': [<BannerInscripciones />, <BannerSonrisa />],
    'kit-corredor': [<BannerKitCorredor />, <BannerSonrisa />],
    'causa-social': [<BannerSonrisa />, <BannerInscripciones />],
}

const IndexPage = () => {
  const [currentSection, setSection] = useState('inscripcion');

  const changeSections = (sectionName) => {
    setSection(sectionName);
  };

  return (
    <Layout>
      <BannerPrincipal selectedSection={changeSections} />
      <BannerRibbon>
        <BannerSwitcher>
          {subBanners[currentSection][0]}
        </BannerSwitcher>
      </BannerRibbon>
      <BannerRibbon translateY={'-1rem'}>
        <BannerViveRSR />
      </BannerRibbon>
      <BannerRibbon translateY={'-2rem'}>
        <BannerSwitcher>
          {subBanners[currentSection][1]}
        </BannerSwitcher>
      </BannerRibbon>
      <BannerRibbon>
        <BannerGaleria />
      </BannerRibbon>
      <BannerRibbon>
        <BannerPatrocinadores />
      </BannerRibbon>
    </Layout>
  );
};

export default IndexPage;

export const Head = () => <Seo title="Home" />
