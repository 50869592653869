import * as React from 'react';
import * as styles from './banner-ribbon.module.css';

const BannerRibbon = ({ children, translateY }) => {
    return (
        <div className={[styles.bannerRibbon].join(' ')} style={{"--translateY": translateY}}>
            {children}
        </div>
    );
};

export default BannerRibbon;