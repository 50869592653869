// extracted by mini-css-extract-plugin
export var bannerPatrocinadores = "banner-patrocinadores-module--bannerPatrocinadores--s6Ido";
export var sponsorLogoItem = "banner-patrocinadores-module--sponsorLogoItem--T00cL";
export var sponsorLogoIzzi = "banner-patrocinadores-module--sponsorLogoIzzi--3tbDj";
export var sponsorLogoMKN = "banner-patrocinadores-module--sponsorLogoMKN--5bu5N";
export var sponsorLogoMaruchan = "banner-patrocinadores-module--sponsorLogoMaruchan--42SOQ";
export var sponsorLogoMilenio = "banner-patrocinadores-module--sponsorLogoMilenio--2BEUw";
export var sponsorLogoPowerade = "banner-patrocinadores-module--sponsorLogoPowerade--Qnr76";
export var sponsorLogoSwissLab = "banner-patrocinadores-module--sponsorLogoSwissLab--3nKzw";
export var sponsorLogoThePilotPanda = "banner-patrocinadores-module--sponsorLogoThePilotPanda--8J9lz";
export var sponsorsContainer = "banner-patrocinadores-module--sponsorsContainer--lkNvs";
export var sponsorsLogoContainer = "banner-patrocinadores-module--sponsorsLogoContainer--nVixH";
export var sponsorsLogoRow = "banner-patrocinadores-module--sponsorsLogoRow--NyGKJ";
export var sponsorsTitle = "banner-patrocinadores-module--sponsorsTitle--Md7QD";
export var sponsorsTitleContainer = "banner-patrocinadores-module--sponsorsTitleContainer--fQ8lF";
export var sponsorsTitleDivider = "banner-patrocinadores-module--sponsorsTitleDivider--msuFc";