import * as React from 'react';
import * as styles from './banner-viversr.module.css';
import * as basestyles from './../layout.module.css';
import Video from '../utils/video';
import { BtnSignUpGDL, BtnSignUpMTY } from '../utils/signupbtn';
import { StaticImage } from 'gatsby-plugin-image';

const BannerViveRSR = () => {
    return (
        <section className={[basestyles.banner, styles.bannerViveRSR].join(' ')}>
            <div className={[basestyles.bannerContainer, styles.viveRSRContainer].join(' ')}>
                <div className={[basestyles.bannerTextContainer, styles.viveRSRTextContainer].join(' ')}>
                    <div className={styles.viveRSRTitleContainer}>
                        <h2 className={styles.viveRSRTitle}>Cómo se vive</h2>
                        <h2 className={styles.viveRSRSubtitle}>Run Santa Run</h2>
                        <div className={[basestyles.bannerTitleDivider, styles.viveRSRTitleDivider].join(' ')}></div>
                    </div>
                    <div className={styles.viveRSRText}>
                        <p>Una ruta llena de sorpresas, ambientación navideña, encuentros cercanos con el Grinch y nieve artificial cayendo del cielo son algunas vivencias que te esperan en la carrera más grande de Santas en México.</p>
                        <p>Para que disfrutes de esta experiencia navideña, tu inscripción incluye el traje de Santa/Elfo (pantalón, saco, gorro y barba) y la medalla conmemorativa RSR.</p>
                    </div>
                </div>
                <div className={styles.signUpContainer}>
                    <div className={styles.signUpItem}>
                        <div className={styles.signUpItemText}>
                            <p className={styles.signUpItemCity}>Monterrey</p>
                            <p className={styles.signUpItemComma}>, </p>
                            <p className={styles.signUpItemDate}>18 de Diciembre 2022</p>
                        </div>
                        <div className={styles.viveRSRBtn}>
                            <BtnSignUpMTY />
                        </div>
                    </div>
                    <div className={styles.signUpDivider}></div>
                    <div className={styles.signUpItem}>
                        <div className={styles.signUpItemText}>
                            <p className={styles.signUpItemCity}>Guadalajara</p>
                            <p className={styles.signUpItemComma}>, </p>
                            <p className={styles.signUpItemDate}>11 de Diciembre 2022</p>
                        </div>
                        <div className={styles.viveRSRBtn}>
                            <BtnSignUpGDL />
                        </div>
                    </div>
                </div>
                <div className={styles.viveRSRVideoContainer}>
                    <Video 
                        videoSrcURL={"sMiora7A3SI"} 
                        videoTitle={"Run Santa Run"} 
                    />
                </div>
                <div className={styles.snowmanViveRSRWrapper}>
                    <StaticImage 
                        alt="Mono de nieve sonriendo"
                        src="./../../images/banners/viversr/snowman-RSR.webp"
                        className={styles.snowmanViveRSR}
                    />
                </div>
            </div>
        </section>
    );
};

export default BannerViveRSR;