import * as React from 'react';
import * as styles from './signupbtn.module.css';

const BtnSignUpGDL = () => {
    return (
        <a className={[styles.btn].join(' ')} href="https://www.trotime.com.mx/Sinsc/Convocatoria?e=598160c5-025a-4615-8ce9-232363e4a670">Inscríbete aquí</a>
    );
};

const BtnSignUpMTY = () => {
    return (
        <a className={[styles.btn].join(' ')} href="https://www.trotime.com.mx/Sinsc/Convocatoria?e=654c0038-c6a0-4725-896c-e2dfe47a6a1e">Inscríbete aquí</a>
    );
};

export { BtnSignUpGDL, BtnSignUpMTY };