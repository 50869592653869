import React from "react"
import * as styles from './video.module.css';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';


const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className={styles.decoratedFrame}>
    <LiteYouTubeEmbed 
        id={videoSrcURL}
        title={videoTitle}
        wrapperClass={styles.RSRVideoFrame}
        iframeClass={styles.RSRVideoiFrame}
        playerClass={styles.RSRVideoPlayBtn}
        aspectWidth={96}
        aspectHeight={54}
        params="autoplay=1"
        webp={true}
    />
  </div>
);
export default Video;
