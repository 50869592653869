import * as React from 'react';
import * as styles from './banner-patrocinadores.module.css';
import * as basestyles from './../layout.module.css';
import { StaticImage } from 'gatsby-plugin-image';

const BannerPatrocinadores = () => {
    return (
        <section className={[basestyles.banner, styles.bannerPatrocinadores].join(' ')}>
            <div className={[basestyles.bannerContainer, styles.sponsorsContainer].join(' ')}>
                <div className={styles.sponsorsTitleContainer}>
                    <h2 className={styles.sponsorsTitle}>Patrocinado por</h2>
                    <div className={[basestyles.bannerTitleDivider, styles.sponsorsTitleDivider].join(' ')}></div>
                </div>
                <div className={styles.sponsorsLogoContainer}>
                    <div className={styles.sponsorsLogoRow}>
                        <StaticImage 
                            alt="Logo de Greyhound"
                            src="./../../images/banners/patrocinadores/greyhound.png"
                            className={styles.sponsorLogoItem}
                        />
                        <StaticImage 
                            alt="Logo de Maruchan Ramen"
                            src="./../../images/banners/patrocinadores/maruchan-ramen.png"
                            className={[styles.sponsorLogoItem, styles.sponsorLogoMaruchan].join(' ')}
                        />
                        <StaticImage 
                            alt="Logo de SwissLab"
                            src="./../../images/banners/patrocinadores/swisslab.png"
                            className={[styles.sponsorLogoItem, styles.sponsorLogoSwissLab].join(' ')}
                        />
                        <StaticImage 
                            alt="Logo de Milenio"
                            src="./../../images/banners/patrocinadores/milenio.png"
                            className={[styles.sponsorLogoItem, styles.sponsorLogoMilenio].join(' ')}
                        />
                    </div>
                    <div className={styles.sponsorsLogoRow}>
                        <StaticImage 
                            alt="Logo de MKN"
                            src="./../../images/banners/patrocinadores/mkn.png"
                            className={[styles.sponsorLogoItem, styles.sponsorLogoMKN].join(' ')}
                        />
                        <StaticImage 
                            alt="Logo de Powerade"
                            src="./../../images/banners/patrocinadores/powerade.png"
                            className={[styles.sponsorLogoItem, styles.sponsorLogoPowerade].join(' ')}
                        />
                        <StaticImage 
                            alt="Logo de The Pilot Panda"
                            src="./../../images/banners/patrocinadores/the-pilot-panda.png"
                            className={[styles.sponsorLogoItem, styles.sponsorLogoThePilotPanda].join(' ')}
                        />
                        <StaticImage 
                            alt="Logo de Izzi"
                            src="./../../images/banners/patrocinadores/izzi.png"
                            className={[styles.sponsorLogoItem, styles.sponsorLogoIzzi].join(' ')}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BannerPatrocinadores;