// extracted by mini-css-extract-plugin
export var bannerViveRSR = "banner-viversr-module--bannerViveRSR--3zaAo";
export var signUpContainer = "banner-viversr-module--signUpContainer--roirV";
export var signUpDivider = "banner-viversr-module--signUpDivider--5LgK-";
export var signUpItem = "banner-viversr-module--signUpItem--rpcgZ";
export var signUpItemCity = "banner-viversr-module--signUpItemCity--3aTPL";
export var signUpItemComma = "banner-viversr-module--signUpItemComma--a9IGf";
export var signUpItemDate = "banner-viversr-module--signUpItemDate--IUTvC";
export var signUpItemText = "banner-viversr-module--signUpItemText--KQXUt";
export var snowmanViveRSR = "banner-viversr-module--snowmanViveRSR--XvKrB";
export var snowmanViveRSRWrapper = "banner-viversr-module--snowmanViveRSRWrapper--K2C4O";
export var viveRSRBtn = "banner-viversr-module--viveRSRBtn--kIemu";
export var viveRSRContainer = "banner-viversr-module--viveRSRContainer--sk4r1";
export var viveRSRSubtitle = "banner-viversr-module--viveRSRSubtitle--bYfg2";
export var viveRSRText = "banner-viversr-module--viveRSRText--u41Z-";
export var viveRSRTextContainer = "banner-viversr-module--viveRSRTextContainer--vVU3Z";
export var viveRSRTitle = "banner-viversr-module--viveRSRTitle--2KQOb";
export var viveRSRTitleContainer = "banner-viversr-module--viveRSRTitleContainer--RdaTX";
export var viveRSRTitleDivider = "banner-viversr-module--viveRSRTitleDivider--dvP7T";
export var viveRSRVideoContainer = "banner-viversr-module--viveRSRVideoContainer--2Qvu3";