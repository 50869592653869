import * as React from 'react';
import * as styles from './banner-principal.module.css';
import * as basestyles from './../layout.module.css';
import { StaticImage } from 'gatsby-plugin-image';
import { BtnSignUpGDL, BtnSignUpMTY } from '../utils/signupbtn';

const BannerPrincipal = ({selectedSection}) => {
    return (
        <section className={styles.bannerPrincipal}>
            <div className={[basestyles.banner, styles.mainSubBanner].join(' ')}>
                <div className={[basestyles.bannerContainer, styles.mainSubBannerContainer].join(' ')}>
                    <div className={styles.logoWrapper}>
                        <StaticImage 
                            alt="Logo de Run Santa Run"
                            src="./../../images/banners/principal/logo-RSR.svg"
                            loading="eager"
                        />
                    </div>
                    <div className={styles.mainTitle}>
                        <h1>Regresa la carrera</h1>
                        <h2>Más mágica de todo el año</h2>
                    </div>
                    <div className={styles.signUpContainer}>
                        <div className={styles.signUpItem}>
                            <h4 className={styles.signUpItemTitle}>Monterrey</h4>
                            <h5 className={styles.signUpItemSubtitle}>18 de Diciembre 2022</h5>
                            <div className={styles.mtySignUpBtn}>
                                <BtnSignUpMTY />
                            </div>
                        </div>
                        <div className={styles.signUpDivider}></div>
                        <div className={styles.signUpItem}>
                            <h4 className={styles.signUpItemTitle}>Guadalajara</h4>
                            <h5 className={styles.signUpItemSubtitle}>11 de Diciembre 2022</h5>
                            <div className={styles.gdlSignUpBtn}>
                                <BtnSignUpGDL />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.sectionsSubBanner}>
                <div className={styles.sectionsSubBannerContainer}>
                    <div className={styles.sectionListContainer}>
                        <ul role="list" className={styles.sectionList}>
                            <li className={styles.sectionItem}>
                                <button className={[styles.sectionItemButton, styles.eventRSRButton].join(' ')} onClick={() => selectedSection('evento')}>
                                    <div className={[styles.sectionItemIconWrapper, basestyles.animate].join(' ')}>
                                        <StaticImage 
                                            alt="Icono para abrir la sección de evento"
                                            src="./../../images/banners/principal/icon-evento.svg"
                                            loading="eager"
                                        />
                                    </div>
                                    <h4>Evento</h4>
                                </button>
                            </li>
                            <div className={styles.sectionDivider}></div>
                            <li className={styles.sectionItem}>
                                <button className={[styles.sectionItemButton, styles.signUpButton].join(' ')} onClick={() => selectedSection('inscripcion')}>
                                    <div className={[styles.sectionItemIconWrapper, basestyles.animate].join(' ')}>
                                        <StaticImage 
                                            alt="Icono para abrir la sección de inscripción"
                                            src="./../../images/banners/principal/icon-inscripcion.svg"
                                            loading="eager"
                                        />
                                    </div>
                                    <h4>Inscripción</h4>
                                </button>
                            </li>
                            <div className={styles.sectionDivider}></div>           
                            <li className={styles.sectionItem}>
                                <button className={[styles.sectionItemButton, styles.runnerKitButton].join(' ')} onClick={() => selectedSection('kit-corredor')}>
                                    <div className={[styles.sectionItemIconWrapper, basestyles.animate].join(' ')}>
                                        <StaticImage 
                                            alt="Icono para abrir la sección de kit corredor"
                                            src="./../../images/banners/principal/icon-kit-corredor.svg"
                                            loading="eager"
                                        />
                                    </div>
                                    <h4>Kit Corredor</h4>
                                </button>       
                            </li>
                            <div className={styles.sectionDivider}></div>
                            <li className={styles.sectionItem}>
                                <button className={[styles.sectionItemButton, styles.socialCauseButton].join(' ')} onClick={() => selectedSection('causa-social')}>
                                    <div className={[styles.sectionItemIconWrapper, basestyles.animate].join(' ')}>
                                        <StaticImage 
                                            alt="Icono para abrir la sección de causa social"
                                            src="./../../images/banners/principal/icon-causa-social.svg"
                                            loading="eager"
                                        />
                                    </div>
                                    <h4>Causa Social</h4>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BannerPrincipal;