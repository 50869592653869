// extracted by mini-css-extract-plugin
export var bannerEvento = "banner-evento-module--bannerEvento--Hg3LF";
export var eventBtn = "banner-evento-module--eventBtn--vIgJp";
export var eventContainer = "banner-evento-module--eventContainer--F3ZlG";
export var eventText = "banner-evento-module--eventText--gXJrC";
export var eventTextContainer = "banner-evento-module--eventTextContainer--9G2I0";
export var gdlSignUpBtn = "banner-evento-module--gdlSignUpBtn--Mk8b1";
export var mtySignUpBtn = "banner-evento-module--mtySignUpBtn--YA+Cs";
export var signUp = "banner-evento-module--signUp--2s7Tt";
export var signUpContainer = "banner-evento-module--signUpContainer--lxbE+";
export var signUpDivider = "banner-evento-module--signUpDivider--afcwq";
export var signUpItem = "banner-evento-module--signUpItem--f3ObM";
export var signUpItemList = "banner-evento-module--signUpItemList--TdXja";
export var signUpItemSubtitle = "banner-evento-module--signUpItemSubtitle--JevUn";
export var signUpItemSubtitleGdl = "banner-evento-module--signUpItemSubtitleGdl--vfNo6";
export var signUpItemSubtitleMty = "banner-evento-module--signUpItemSubtitleMty--9zLeA";
export var signUpItemText = "banner-evento-module--signUpItemText--HlHYp";
export var signUpItemTitle = "banner-evento-module--signUpItemTitle--hm5T0";
export var signUpItemTitleGdl = "banner-evento-module--signUpItemTitleGdl--QC78b";
export var signUpItemTitleMty = "banner-evento-module--signUpItemTitleMty--EjhIz";