import * as React from 'react';
import * as styles from './banner-sonrisa.module.css';
import * as basestyles from './../layout.module.css';
import { StaticImage } from 'gatsby-plugin-image';

const BannerSonrisa = () => {
    return (
        <section className={[basestyles.banner, styles.bannerSonrisa].join(' ')}>
            <div className={[basestyles.bannerContainer, styles.smileContainer, basestyles.loadAnimation].join(' ')}>
                <div className={styles.smilePictureContainer}>
                    <StaticImage 
                        alt="Niños felices, parte del programa Corriendo por Una Sonrisa."
                        src="./../../images/banners/sonrisa/image-sonrisa.webp"
                    />
                </div>
                <div className={[basestyles.bannerTextContainer, styles.smileTextContainer].join(' ')}>
                    <div className={styles.smileTitleContainer}>
                        <h2 className={styles.smileTitle}>Corriendo por</h2>
                        <h2 className={styles.smileSubTitle}>una sonrisa</h2>
                        <div className={[basestyles.bannerTitleDivider, styles.smileTitleDivider].join(' ')}></div>
                    </div>
                    <div className={styles.smileText}>
                        <h5>¡Al igual que TÚ, queremos cerrar el año poniendo un granito de arena!</h5>
                        <p>Por ello, <span>al comprar tu boleto</span> de Run Santa Run, formarás parte de nuestro programa <span>Corriendo por Una Sonrisa</span>, apoyando a la <span>compra de juguetes para llevarlos como regalo de Santa Claus</span> esta Navidad a los niños de zonas marginadas y de escasos recursos.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BannerSonrisa;