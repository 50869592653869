import * as React from 'react';

const BannerSwitcher = ({ children }) => {
    return (
        <>
            {children}
        </>
    );
};

export default BannerSwitcher;