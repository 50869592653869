// extracted by mini-css-extract-plugin
export var bannerKitCorredor = "banner-kit-corredor-module--bannerKitCorredor--Mmq9W";
export var grinchImageWrapper = "banner-kit-corredor-module--grinchImageWrapper--DD+5D";
export var jengiImageWrapper = "banner-kit-corredor-module--jengiImageWrapper--jRYsu";
export var runnerKitContainer = "banner-kit-corredor-module--runnerKitContainer--Hw4bJ";
export var runnerKitDonation = "banner-kit-corredor-module--runnerKitDonation--r9kFh";
export var runnerKitElf = "banner-kit-corredor-module--runnerKitElf--r2pNq";
export var runnerKitHorizontalDivider = "banner-kit-corredor-module--runnerKitHorizontalDivider--Awx3B";
export var runnerKitImageWrapper = "banner-kit-corredor-module--runnerKitImageWrapper--o8t7N";
export var runnerKitItem = "banner-kit-corredor-module--runnerKitItem--9XU62";
export var runnerKitItemContent = "banner-kit-corredor-module--runnerKitItemContent--vUbDU";
export var runnerKitList = "banner-kit-corredor-module--runnerKitList--kd9Vv";
export var runnerKitRecommendation = "banner-kit-corredor-module--runnerKitRecommendation--h1ktX";
export var runnerKitSanta = "banner-kit-corredor-module--runnerKitSanta--TgU4G";
export var runnerKitSubtitleElf = "banner-kit-corredor-module--runnerKitSubtitleElf--Dx7nx";
export var runnerKitTextContainer = "banner-kit-corredor-module--runnerKitTextContainer--mMPOl";
export var runnerKitTitle = "banner-kit-corredor-module--runnerKitTitle--Z3Njy";
export var runnerKitTitleElf = "banner-kit-corredor-module--runnerKitTitleElf--LFbO5";
export var runnerKitTitleSanta = "banner-kit-corredor-module--runnerKitTitleSanta--UzIuQ";