import * as React from 'react';
import * as styles from './banner-inscripciones.module.css';
import * as basestyles from './../layout.module.css';
import { StaticImage } from 'gatsby-plugin-image';

const BannerInscripciones = () => {
    return (
        <section className={[basestyles.banner, styles.bannerInscripciones].join(' ')}>
            <div className={[basestyles.bannerContainer, styles.signUpContainer, basestyles.loadAnimation].join(' ')}>
                <div className={styles.signUpKitContainer}>
                    <div className={[styles.signUpKit, styles.signUpSantaKit].join(' ')}>
                        <div className={styles.signUpKitImageWrapper}>
                            <StaticImage 
                                alt="El Grinch vestido de Santa, con ambos brazos levantados."
                                src="./../../images/banners/inscripciones/grinch-signup-RSR.webp"
                            />
                        </div>
                        <div className={[styles.signUpKitTextContainer, styles.signUpKitTextContainerSanta].join(' ')}>
                            <h3 className={styles.signUpKitTitle}>Santa Claus Kit</h3>
                            <h5 className={styles.signUpKitDistance}>1k, 5k, y 10k</h5>
                            <h4 className={styles.signUpKitUsers}>Adultos, femenil y varonil</h4>
                            <p className={[styles.signUpKitPrice, styles.signUpKitPriceSanta].join(' ')}>$380</p>
                        </div>
                    </div>
                    <div className={[styles.signUpKit, styles.signUpElfKit].join(' ')}>
                        <div className={styles.signUpKitImageWrapper}>
                            <StaticImage 
                                alt="Un duende, sonriendo y con ambos brazos levantados."
                                src="./../../images/banners/inscripciones/elf-signup-RSR.webp"
                            />
                        </div>
                        <div className={[styles.signUpKitTextContainer, styles.signUpKitTextContainerElf].join(' ')}>
                            <h3 className={styles.signUpKitTitle}>Elfo Kit</h3>
                            <h5 className={styles.signUpKitDistance}>1k, 5k, y 10k</h5>
                            <h4 className={styles.signUpKitUsers}>Menores de 12 años</h4>
                            <p className={[styles.signUpKitPrice, styles.signUpKitPriceElf].join(' ')}>$330</p>
                        </div>
                    </div>
                </div>
                <div className={styles.signUpTextContainer}>
                    <h5 className={styles.signUpSubtitle}>Te esperan muchas amenidades para disfrutar en grande</h5>
                    <ul role="list" className={styles.signUpActivitiesList}>
                        <li>Ambientación navideña</li>
                        <div className={styles.listSeparator}></div>
                        <li>Evento familiar</li>
                        <div className={styles.listSeparator}></div>
                        <li>Foto con Santa Claus</li>
                        <div className={styles.listSeparator}></div>
                        <li>Rifas y sorpresas</li>
                    </ul>
                    <ul role="list" className={styles.signUpActivitiesList}>
                        <li>Causa social</li>
                        <div className={styles.listSeparator}></div>
                        <li>Actividades después de la carrera</li>
                        <div className={styles.listSeparator}></div>
                        <li>Experiencias con patrocinadores</li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default BannerInscripciones;