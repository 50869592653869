// extracted by mini-css-extract-plugin
export var bannerGaleria = "banner-galeria-module--bannerGaleria--Grssu";
export var galleryContainer = "banner-galeria-module--galleryContainer--TDtSh";
export var galleryPhoto = "banner-galeria-module--galleryPhoto--kEo3Z";
export var galleryPhotosContainer = "banner-galeria-module--galleryPhotosContainer--WV2eq";
export var galleryPhotosRow = "banner-galeria-module--galleryPhotosRow--cR8gF";
export var gallerySubTitle = "banner-galeria-module--gallerySubTitle--6yIvx";
export var galleryTitle = "banner-galeria-module--galleryTitle--7dGYg";
export var galleryTitleContainer = "banner-galeria-module--galleryTitleContainer--MMoV+";
export var santaGalleryRSR = "banner-galeria-module--santaGalleryRSR--Rcn0U";
export var santaGalleryRSRWrapper = "banner-galeria-module--santaGalleryRSRWrapper--E8E3r";