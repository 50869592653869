import * as React from 'react';
import * as styles from './banner-evento.module.css';
import * as basestyles from './../layout.module.css';
import { BtnSignUpGDL, BtnSignUpMTY } from '../utils/signupbtn';

const BannerEvento = () => {
    return (
        <section className={[basestyles.banner, styles.bannerEvento].join(' ')}>
            <div className={[basestyles.bannerContainer, styles.eventContainer, basestyles.loadAnimation].join(' ')}>
                <div className={styles.signUpContainer}>
                    <div className={styles.signUpItem}>
                        <div className={styles.signUpItemText}>
                            <h3 className={[styles.signUpItemTitle, styles.signUpItemTitleMty].join(' ')}>Monterrey</h3>
                            <h5 className={[styles.signUpItemSubtitle, styles.signUpItemSubtitleMty].join(' ')}>Lugar de entrega de kits</h5>
                            <ul className={styles.signUpItemList}>
                                <li>Innovasport San Pedro (Humberto Lobo)</li>
                                <li>Sábado 17 de Diciembre</li>
                                <li>10:00 am. - 2:00 pm.</li>
                            </ul>
                        </div>
                        <div className={[styles.mtySignUpBtn, styles.eventBtn].join(' ')}>
                            <BtnSignUpMTY />
                        </div>
                    </div>
                    <div className={styles.signUpDivider}></div>
                    <div className={styles.signUpItem}>
                        <div className={styles.signUpItemText}>
                            <h3 className={[styles.signUpItemTitle, styles.signUpItemTitleGdl].join(' ')}>Guadalajara</h3>
                            <h5 className={[styles.signUpItemSubtitle, styles.signUpItemSubtitleGdl].join(' ')}>Lugar de entrega de kits</h5>
                            <ul className={styles.signUpItemList}>
                                <li>Parque Metropolitano</li>
                                <li>Sábado 10 de Diciembre</li>
                                <li>10:00 am. - 2:00 pm.</li>
                            </ul>
                        </div>
                        <div className={[styles.gdlSignUpBtn, styles.eventBtn].join(' ')}>
                            <BtnSignUpGDL />
                        </div>
                    </div>
                </div>
                <div className={[basestyles.bannerTextContainer, styles.eventTextContainer].join(' ')}>
                    <div className={styles.eventText}>
                        <p>Al momento de recibir cualquier paquete es OBLIGATORIO entregar el comprobante de pago con el folio de registro. En caso de no llevar folio, llevar una copia de su INE.</p>
                        <p>Para el ELFO KIT se deberá llevar consigo algún tipo de identificación del menor.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BannerEvento;