import * as React from 'react';
import * as styles from './banner-galeria.module.css';
import * as basestyles from './../layout.module.css';
import { StaticImage } from 'gatsby-plugin-image';

const BannerGaleria = () => {
    return (
        <section className={[basestyles.banner, styles.bannerGaleria].join(' ')}>
            <div className={[basestyles.bannerContainer, styles.galleryContainer].join(' ')}>
                <div className={styles.galleryTitleContainer}>
                    <h1 className={styles.galleryTitle}>La carrera más navideña</h1>
                    <h2 className={styles.gallerySubTitle}>Del año te espera</h2>
                </div>
                <div className={styles.galleryPhotosContainer}>
                    <div className={styles.galleryPhotosRow}>
                        <StaticImage 
                            alt=""
                            src="./../../images/banners/galeria/image-gallery-1.webp"
                            className={styles.galleryPhoto}
                        />
                        <StaticImage 
                            alt=""
                            src="./../../images/banners/galeria/image-gallery-2.webp"
                            className={styles.galleryPhoto}
                        />
                        <StaticImage 
                            alt=""
                            src="./../../images/banners/galeria/image-gallery-3.webp"
                            className={styles.galleryPhoto}
                        />
                        <StaticImage 
                            alt=""
                            src="./../../images/banners/galeria/image-gallery-4.webp"
                            className={styles.galleryPhoto}
                        />
                        <StaticImage 
                            alt=""
                            src="./../../images/banners/galeria/image-gallery-5.webp"
                            className={styles.galleryPhoto}
                        />
                        <StaticImage 
                            alt=""
                            src="./../../images/banners/galeria/image-gallery-6.webp"
                            className={styles.galleryPhoto}
                        />
                        <StaticImage 
                            alt=""
                            src="./../../images/banners/galeria/image-gallery-7.webp"
                            className={styles.galleryPhoto}
                        />
                    </div>
                </div>
                <div className={styles.santaGalleryRSRWrapper}>
                    <StaticImage 
                        alt="Santa Claus sonriendo y con las manos en el aire"
                        src="./../../images/banners/galeria/santa-gallery-RSR.png"
                        className={styles.santaGalleryRSR}
                    />
                </div>
            </div>
        </section>
    );
};

export default BannerGaleria;