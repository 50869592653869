// extracted by mini-css-extract-plugin
export var bannerPrincipal = "banner-principal-module--bannerPrincipal--xFnkG";
export var eventRSRButton = "banner-principal-module--eventRSRButton--h7eUJ";
export var gdlSignUpBtn = "banner-principal-module--gdlSignUpBtn--3gEz2";
export var logoWrapper = "banner-principal-module--logoWrapper--JVcr-";
export var mainSubBanner = "banner-principal-module--mainSubBanner--wFsMu";
export var mainSubBannerContainer = "banner-principal-module--mainSubBannerContainer--pO+ym";
export var mainTitle = "banner-principal-module--mainTitle--DiuYN";
export var mtySignUpBtn = "banner-principal-module--mtySignUpBtn--yXqz3";
export var runnerKitButton = "banner-principal-module--runnerKitButton--apV2g";
export var sectionDivider = "banner-principal-module--sectionDivider--QkFQ7";
export var sectionItem = "banner-principal-module--sectionItem--IeMLH";
export var sectionItemButton = "banner-principal-module--sectionItemButton--Pz-vA";
export var sectionItemIconWrapper = "banner-principal-module--sectionItemIconWrapper--w-0Iz";
export var sectionList = "banner-principal-module--sectionList--obRVU";
export var sectionListContainer = "banner-principal-module--sectionListContainer--TOcs2";
export var sectionsSubBanner = "banner-principal-module--sectionsSubBanner--8FrNo";
export var sectionsSubBannerContainer = "banner-principal-module--sectionsSubBannerContainer--4yKm-";
export var signUpButton = "banner-principal-module--signUpButton--psCFm";
export var signUpContainer = "banner-principal-module--signUpContainer--IeKn9";
export var signUpDivider = "banner-principal-module--signUpDivider--2Vfk2";
export var signUpItem = "banner-principal-module--signUpItem--1PQel";
export var signUpItemSubtitle = "banner-principal-module--signUpItemSubtitle--rNiWG";
export var signUpItemTitle = "banner-principal-module--signUpItemTitle--4+FpW";
export var socialCauseButton = "banner-principal-module--socialCauseButton--bM-Tn";