// extracted by mini-css-extract-plugin
export var bannerInscripciones = "banner-inscripciones-module--bannerInscripciones--YF2MC";
export var listSeparator = "banner-inscripciones-module--listSeparator--2xZmV";
export var signUpActivitiesList = "banner-inscripciones-module--signUpActivitiesList--s9AvJ";
export var signUpContainer = "banner-inscripciones-module--signUpContainer--OkbZt";
export var signUpElfKit = "banner-inscripciones-module--signUpElfKit--nDX3T";
export var signUpKit = "banner-inscripciones-module--signUpKit--LqfvI";
export var signUpKitContainer = "banner-inscripciones-module--signUpKitContainer--wSWsQ";
export var signUpKitDistance = "banner-inscripciones-module--signUpKitDistance--JBHpa";
export var signUpKitImageWrapper = "banner-inscripciones-module--signUpKitImageWrapper--gpKh7";
export var signUpKitPrice = "banner-inscripciones-module--signUpKitPrice--Q5Vyo";
export var signUpKitPriceElf = "banner-inscripciones-module--signUpKitPriceElf--c9Dx0";
export var signUpKitPriceSanta = "banner-inscripciones-module--signUpKitPriceSanta--8J+pI";
export var signUpKitTextContainer = "banner-inscripciones-module--signUpKitTextContainer--D6pSj";
export var signUpKitTextContainerElf = "banner-inscripciones-module--signUpKitTextContainerElf--8l+NN";
export var signUpKitTextContainerSanta = "banner-inscripciones-module--signUpKitTextContainerSanta--1HyDs";
export var signUpKitTitle = "banner-inscripciones-module--signUpKitTitle--j9BB3";
export var signUpKitUsers = "banner-inscripciones-module--signUpKitUsers--a0B7q";
export var signUpSantaKit = "banner-inscripciones-module--signUpSantaKit--rYxka";
export var signUpSubtitle = "banner-inscripciones-module--signUpSubtitle--zFfXJ";
export var signUpTextContainer = "banner-inscripciones-module--signUpTextContainer--I0ToO";